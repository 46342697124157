import mrShoukat from "../Assets/shoukat.jpeg";
import mrWaqas from "../Assets/waqas.png";
import mrSajid from "../Assets/sajid.jpg";
import mrHassan from "../Assets/hassan.jpeg";
import mrsAyesha from "../Assets/ayesha.jpeg";
import mrSaiban from "../Assets/saiban.png";

import d1 from "../Assets/PIC1.png";
import d2 from "../Assets/PIC2.png";
import d3 from "../Assets/PIC3.png";
import d4 from "../Assets/PIC4.png";
import d5 from "../Assets/PIC5.png";
import d6 from "../Assets/PIC6.png";
import d7 from "../Assets/PIC7.png";
import d8 from "../Assets/PIC8.png";
import d9 from "../Assets/PIC9.png";
import d10 from "../Assets/PIC10.png";
import d11 from "../Assets/PIC11.png";
import d12 from "../Assets/PIC12.png";
import d13 from "../Assets/PIC13.png";
import d14 from "../Assets/PIC14.png";
import d15 from "../Assets/PIC15.png";
import l1 from "../Assets/logo1.png";
import l2 from "../Assets/logo2.png";
import l3 from "../Assets/logo3.png";
import l4 from "../Assets/logo4.png";
import l5 from "../Assets/logo5.png";
import l6 from "../Assets/logo6.png";
import l7 from "../Assets/logo7.png";
import l8 from "../Assets/logo8.png";
import l9 from "../Assets/logo9.png";
import l10 from "../Assets/logo10.png";
import l11 from "../Assets/logo11.png";
import l12 from "../Assets/logo12.png";
import l13 from "../Assets/logo13.png";
import l14 from "../Assets/logo14.png";
import l15 from "../Assets/logo15.png";
import l16 from "../Assets/logo16.png";
import l17 from "../Assets/logo17.png";
import l18 from "../Assets/logo18.png";
import l19 from "../Assets/logo19.png";
import l20 from "../Assets/logo20.png";
import l21 from "../Assets/logo21.png";
import l22 from "../Assets/logo22.png";
import l23 from "../Assets/logo23.png";
import l24 from "../Assets/logo24.png";
import l25 from "../Assets/logo25.png";

export const teamData = [
  {
    id: 1,
    imgSrc: mrShoukat,
    name: "Makhdoom Shoukat Ali Qureshi",
    designation: "CEO & FOUNDER",
  },
  {
    id: 2,
    imgSrc: mrWaqas,
    name: "Waqas Abbasi",
    designation: "Head of Human Resources & Administration",
  },
  {
    id: 3,
    imgSrc: mrSajid,
    name: "Muhammad Sajid",
    designation: "Chief Financial Officer",
  },
  {
    id: 4,
    imgSrc: mrsAyesha,
    name: "Ayesha Saeeda",
    designation: "Accounts Manager",
  },
  {
    id: 5,
    imgSrc: mrHassan,
    name: "Hassan Ali",
    designation: "IT Manager",
  },
  {
    id: 5,
    imgSrc: mrSaiban,
    name: "Saiban Ali",
    designation: "Chief Technical Officer",
  },
];

export const contactData = {
  phone: "+971 6 579 0899",
  mobile: "+971 50 763 3455",
  email: "info@alnaboodahr.com",
  address: "Ofiice # 504, Al Hisn Tower, Bank Steer, Rolla, Sharjah",
};

export const demandData = [
  {
    id: 1,
    image: d1,
  },
  {
    id: 2,
    image: d2,
  },
  {
    id: 3,
    image: d3,
  },
  {
    id: 4,
    image: d4,
  },
  {
    id: 5,
    image: d5,
  },
  {
    id: 6,
    image: d6,
  },
  {
    id: 7,
    image: d7,
  },
  {
    id: 8,
    image: d8,
  },
  {
    id: 9,
    image: d9,
  },
  {
    id: 10,
    image: d10,
  },
  {
    id: 11,
    image: d11,
  },
  {
    id: 12,
    image: d12,
  },
  {
    id: 13,
    image: d13,
  },
  {
    id: 14,
    image: d14,
  },
  {
    id: 15,
    image: d15,
  },
];

export const logoData = [
  {
    id: 1,
    image: l1,
  },
  {
    id: 2,
    image: l2,
  },
  {
    id: 3,
    image: l3,
  },
  {
    id: 4,
    image: l4,
  },
  {
    id: 5,
    image: l5,
  },
  {
    id: 6,
    image: l6,
  },
  {
    id: 7,
    image: l7,
  },
  {
    id: 8,
    image: l8,
  },
  {
    id: 9,
    image: l9,
  },
  {
    id: 10,
    image: l10,
  },
  {
    id: 11,
    image: l11,
  },
  {
    id: 12,
    image: l12,
  },
  {
    id: 13,
    image: l13,
  },
  {
    id: 14,
    image: l14,
  },
  {
    id: 15,
    image: l15,
  },
  {
    id: 16,
    image: l16,
  },
  {
    id: 17,
    image: l17,
  },
  {
    id: 18,
    image: l18,
  },
  {
    id: 19,
    image: l19,
  },
  {
    id: 20,
    image: l20,
  },
  {
    id: 21,
    image: l21,
  },
  {
    id: 22,
    image: l22,
  },
  {
    id: 23,
    image: l23,
  },
  {
    id: 24,
    image: l24,
  },
  {
    id: 25,
    image: l25,
  },
];
